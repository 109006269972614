<template lang='pug'>
.event-contact
  h2.event-contact__title.title Предварительная запись обязательна
  p.event-contact__text
    | Узнайте о новых мероприятиях и запишитесь по телефону:
  a.event-contact__link.title(:href="phone.general.link") {{phone.general.mask}}
  .event-contact__social-box
    a.event-contact__social(:class="`event-contact__social_${whatsAppLink.mod}`", :href="whatsAppLink.link", :title="whatsAppLink.name", target="_blank", rel="noopener nofollow noreferrer")
</template>

<script>
import '@/assets/styles/components/event-contact.sass'
import { phone } from '../assets/scripts/variables'
import { whatsAppLink } from '../assets/scripts/socials'

export default {
  name: 'EventContact',
  data () {
    return {
      phone,
      whatsAppLink
    }
  }
}
</script>
